import * as React from "react"
import styled from "styled-components"

// see bean styling in index
const ListName = styled.div`

    h3 {
        color: rgba(0, 0, 0, .78);
        margin: 0;
        margin: 18px 0 18px 24px;

        @media (max-width:640px) {
            margin: 18px 0;
            font-size: 21px;
            text-align: center;
        }
    }
`

const List = props => (
    <a href={props.url} className="LinksOfKnowledge">
        <ListName>
            <h3>{props.title}</h3>
        </ListName>
    </a>
)

export default List