import * as React from "react"
// import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/Layout"
import List from "../components/List"
import "../components/Layout.css"
import Seo from "../components/Seo"

//styles
const SheeshWrapper = styled.div`
  margin: 0 auto;
  max-width: 1120px;


  @media (max-width:640px) {
    width: 100%;
  }
`

const SheeshDefinition = styled.div`

  h2, h3 {
    color: white;
  }
    
    a {
      color: white;
    }

    a:hover {
      color: black;
    }
  
  h3 {
    margin-top: 0;
  
    @media (max-width:640px) {
      ${'' /* text-align: center; */}
      line-height: 1.2;
      font-size: 17px;
    }
  }

  p {

    @media (max-width:640px) {
      font-weight: 500;
      font-size: 18px;
    }
  }

  p.linkto {

    @media (max-width:640px) {
     text-align: center;
     font-weight: 600;
     font-size: 21px;
    }
  }
`

const Lists = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  grid-gap: 1.75vw;
  margin-top: 24px;

  .LinksOfKnowledge:nth-child(4n+1) {
    background-color: rgba(108, 211, 166, 1);
  }

  .LinksOfKnowledge:nth-child(4n+2) {
    background-color: rgba(245, 241, 152, 1);
  }

  .LinksOfKnowledge:nth-child(4n+3) {
    background-color: rgba(255, 157, 61, 1);
  }

  .LinksOfKnowledge:nth-child(4n+4) {
    background-color: rgba(60, 200, 215, 1);
  }

  .LinksOfKnowledge:hover {
    background: black;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .25);
    transform: translateY(-3px);

    h3 {
      color: white;
    }
  }

  @media (max-width:640px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;

    .LinksOfKnowledge:nth-child(n) {
      margin-bottom: 12px;
    }
  }

`

const Sheesh = () => (
    <Layout>
        <Seo title="Sheeesh" />
        <div className="Sheesh">
        <h1 className="hideMe">Sheeesh</h1>
        <SheeshWrapper>
            <SheeshDefinition>
            <h2><a href="https://www.tiktok.com/tag/sheesh?lang=en" target="_blank" rel="noreferrer">sheeeesh</a> <span className="dictionary">shēsh</span></h2>
            <h3><span className="dictionary">interj.</span> used to express the best of the best</h3>
            </SheeshDefinition>
            <Lists>
            {/* <List
                title="aapi eats LA"
                url="/aapi-eats-la"
            /> */}
            <List
                title="aapi stories for kids age 4-9"
                url="/aapi-stories-4-9"
            />
            {/* <List
                title="bike rides to burritos LA"
                url="/bike-to-burritos-la"
            />
            <List
                title="cooking hacks"
                url="/cooking-hacks"
            /> */}
            </Lists>
        </SheeshWrapper>
        </div>
    </Layout>
  )

export default Sheesh